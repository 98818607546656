import { useMutation, useQuery, useQueryClient } from 'react-query';
import { store } from '../store';
import { selectPreviewCandidate } from '../store/selectors/Candidate';
import { setPreviewCandidate } from '../store/slices/Candidate';
import CandidateService from './../services/Candidate';
import queryStore from '.';

export const CANDIDATE_QUERY_KEY = 'candidate';

queryStore.setQueryDefaults(CANDIDATE_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

export const useGetCandidatesQuery = (queryParams, queryOptions) =>
  useQuery(
    [CANDIDATE_QUERY_KEY, queryParams],
    async () => {
      const { candidates_list } =
        await CandidateService.getCandidates(queryParams);
      return candidates_list;
    },
    queryOptions
  );

export const useUpdateCandidateProfileStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ candidate_profile_status_id, profile_status }) =>
      CandidateService.updateCandidateProfileStatus(
        candidate_profile_status_id,
        profile_status
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CANDIDATE_QUERY_KEY]);
      }
    }
  );
};

export const useGetCandidateProfileTemplateDetailQuery = (
  queryParams,
  queryOptions
) =>
  useQuery({
    ...queryOptions,
    queryKey: [CANDIDATE_QUERY_KEY, queryParams],
    queryFn: async () =>
      await CandidateService.getCandidateProfileTemplateDetail(queryParams)
  });

export const useGetComplianceResponseDocsQuery = (
  queryParams,
  queryOptions
) =>
  useQuery(
    [CANDIDATE_QUERY_KEY, queryParams],
    async () => {
      const data =
        await CandidateService.getComplianceResponseDocs(queryParams);
      return data;
    },
    queryOptions
  );

export const useToggleCandidateFavoriteStatusQuery = (queryParams) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ candidate_profile_status_id, is_favourite }) =>
      CandidateService.toggleCandidateFavoriteStatus(
        candidate_profile_status_id,
        is_favourite ? 0 : 1
      ),
    {
      onMutate: (candidate) => {
        if (selectPreviewCandidate(store.getState())) {
          store.dispatch(
            setPreviewCandidate({
              ...candidate,
              is_favourite: !candidate.is_favourite
            })
          );
        }

        queryClient.setQueryData(
          [CANDIDATE_QUERY_KEY, queryParams],
          (old) =>
            old.map((oldCandidate) =>
              oldCandidate.candidate_profile_status_id ===
              candidate.candidate_profile_status_id
                ? {
                    ...oldCandidate,
                    is_favourite: !candidate?.is_favourite
                  }
                : oldCandidate
            )
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(CANDIDATE_QUERY_KEY);
      },
      onError: (_error, candidate) => {
        if (selectPreviewCandidate(store.getState())) {
          store.dispatch(
            setPreviewCandidate({
              ...candidate,
              is_favourite: candidate.is_favourite
            })
          );
        }

        queryClient.setQueryData(
          [CANDIDATE_QUERY_KEY, queryParams],
          (old) =>
            old.map((oldCandidate) =>
              oldCandidate.candidate_profile_status_id ===
              candidate.candidate_profile_status_id
                ? {
                    ...oldCandidate,
                    is_favourite: candidate?.is_favourite
                  }
                : oldCandidate
            )
        );
      }
    }
  );
};

export const useUpdateCandidateRequirementsQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      candidate_profile_status_id,
      requirements,
      to_remove,
      template_name
    }) =>
      CandidateService.updateCandidateRequirements({
        template_name,
        candidate_profile_status_id,
        requirements,
        to_remove
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CANDIDATE_QUERY_KEY);
      }
    }
  );
};
