import { useMutation, useQuery, useQueryClient } from 'react-query';
import InterviewService from './../services/Interview';
import queryStore from '.';
import { CANDIDATE_QUERY_KEY } from './Candidate';

export const INTERVIEW_QUERY_KEY = 'interview';

export const ROOM_CODE_QUERY_KEY = 'room_code';

queryStore.setQueryDefaults(INTERVIEW_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

export const useGetInterviewsQuery = () =>
  useMutation(async ({ startDate, endDate }) => {
    const res = await InterviewService.getInterviews({
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString()
    });
    return res;
  });

export const useSubmitPostInterviewMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ interview_note, status, client_id, interview_id }) => {
      const res = await InterviewService.submitPostInterview({
        interview_note,
        status,
        client_id,
        interview_id
      });
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INTERVIEW_QUERY_KEY]);
        queryClient.invalidateQueries([CANDIDATE_QUERY_KEY]);
      }
    }
  );
};

export const useGetInterviewInfoByHostRoomCodeQuery = (
  { host_room_code },
  queryOptions
) =>
  useQuery(
    [INTERVIEW_QUERY_KEY, ROOM_CODE_QUERY_KEY, host_room_code],
    async () => {
      const res = await InterviewService.getInterviewInfoByHostRoomCode({
        host_room_code
      });
      return res.client_interview;
    },
    queryOptions
  );

export const useGetSingleInterviewQuery = (
  { interview_id },
  queryOptions
) =>
  useQuery(
    [INTERVIEW_QUERY_KEY, interview_id],
    async () => {
      const res = await InterviewService.getSingleInterview({
        interview_id
      });
      return res.client_interview;
    },
    queryOptions
  );

export const useUpdateClientInterviewStatusQuery = () => {
  return useMutation(
    async ({ interview_id, status }) =>
      await InterviewService.updateClientInterviewStatus({
        interview_id,
        status
      })
  );
};

export const useInitializeInterviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ candidate_id, campaign_id, dates }) =>
      await InterviewService.initializeInterview({
        candidate_id,
        campaign_id,
        dates
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CANDIDATE_QUERY_KEY);
      }
    }
  );
};

export const useRescheduleInterviewMutation = () => {
  return useMutation(
    async ({ candidate_id, campaign_id, dates }) =>
      await InterviewService.rescheduleInterview({
        candidate_id,
        campaign_id,
        dates
      })
  );
};

export const useConfirmInterviewDateMutation = () => {
  return useMutation(
    async ({ candidate_id, campaign_id, timeslot, client_id }) =>
      await InterviewService.confirmInterviewDate({
        candidate_id,
        campaign_id,
        timeslot,
        client_id
      })
  );
};

export const useRequestInterviewReschedulingMutation = () => {
  return useMutation(
    async ({ candidate_id, campaign_id, client_id }) =>
      await InterviewService.requestNewInterviewSlots({
        candidate_id,
        campaign_id,
        client_id
      })
  );
};
