import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import classNames from 'classnames';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import { INTERVIEW_STATUSES } from '../../constants/common';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import './styles.scss';
import { useSubmitPostInterviewMutation } from '@src/queries/Interview';

const PostInterviewForm = ({
  submitButton,
  cancelButton,
  clientId,
  interviewId
}) => {
  const [interviewStatus, setInterviewStatus] = useState();
  const [interviewNote, setInterviewNote] = useState('');

  const { mutateAsync: submitPostInterview } =
    useSubmitPostInterviewMutation();

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <Box>
        <Typography textAlign="center">
          What do you feel about the candidate?
        </Typography>
      </Box>
      <Grid container gap="1.5rem" justifyContent="center">
        <Grid
          item
          className={classNames('icon-container thumb-up', {
            active: interviewStatus === INTERVIEW_STATUSES.VOTED_UP
          })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (interviewStatus === INTERVIEW_STATUSES.VOTED_UP) {
              return;
            }

            setInterviewStatus(INTERVIEW_STATUSES.VOTED_UP);
          }}>
          <ThumbUpRoundedIcon className="styled-icon" />
        </Grid>
        <Grid
          item
          className={classNames('icon-container thumb-down', {
            active: interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN
          })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN) {
              return;
            }

            setInterviewStatus(INTERVIEW_STATUSES.VOTED_DOWN);
          }}>
          <ThumbDownRoundedIcon className="styled-icon" />
        </Grid>
      </Grid>
      <Box>
        <TextField
          multiline
          sx={{ width: '320px' }}
          rows={3}
          onChange={(e) => setInterviewNote(e.target.value)}
        />
      </Box>
      <Box display="flex" gap="1.5rem" justifyContent="center">
        {cancelButton && (
          <Button
            width="150px"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              cancelButton.onClick();
            }}>
            {cancelButton.text}
          </Button>
        )}
        <LoadingButton
          variant="contained"
          sx={{ width: '100px' }}
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            await submitPostInterview({
              interview_id: interviewId,
              client_id: clientId,
              status: interviewStatus,
              interview_note: interviewNote
            });
            submitButton.onClick();
          }}>
          {submitButton.text}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default PostInterviewForm;
