import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {
  GRAPH_CATEGORY,
  GRAPH_PERIOD
} from '../../../constants/horizontal-graph';
import { useGetStatsQuery } from '../../../queries/Stats';
import {
  getChartDataByVote,
  getChartDataByWorkedHours
} from '../../../utils/horizontal-graph';
import { GraphFilterSelect } from './graph-filter-select';

import './styles/HorizontalGraph.scss';

const HorizontalGraph = ({ containerClassName, selectedCampaign }) => {
  const [selectingGraphCategory, setSelectingGraphCategory] = useState(
    GRAPH_CATEGORY.VOTE
  );
  const [selectingGraphPeriod, setSelectingGraphPeriod] = useState(
    GRAPH_PERIOD.LAST_3_DAYS
  );

  const [hiddenLine, setHiddenLine] = useState(null);

  const queryParams = {
    campaign_id: selectedCampaign?.campaign_id || null
  };

  const { isSuccess, data } = useGetStatsQuery(queryParams);

  const { series: dataByVoteSeries } = isSuccess
    ? getChartDataByVote(
        selectingGraphPeriod,
        data.candidates_data.records
      )
    : {};

  const { series: workedHoursSeries } = isSuccess
    ? getChartDataByWorkedHours(
        selectingGraphPeriod,
        data.candidates_data.records
      )
    : {};
  console.log(
    '🚀 ~ HorizontalGraph ~ workedHoursSeries:',
    workedHoursSeries
  );

  const getGraphOptions = (optionObject) =>
    Object.values(optionObject).map((value) => ({
      value,
      label: value
    }));

  const graphCategoryOptions = getGraphOptions(GRAPH_CATEGORY);

  const graphDurationOptions = getGraphOptions(GRAPH_PERIOD);

  const resultSeries =
    selectingGraphCategory === GRAPH_CATEGORY.VOTE
      ? dataByVoteSeries
      : workedHoursSeries;

  return (
    <div
      className={
        'horizontal-graph p-0' +
        (containerClassName ? ` ${containerClassName}` : '')
      }>
      <div className="absolute z-10 flex gap-4 left-4 top-5">
        <GraphFilterSelect
          value={selectingGraphCategory}
          onChange={({ target: { value } }) =>
            setSelectingGraphCategory(value)
          }
          options={graphCategoryOptions}
        />
        <GraphFilterSelect
          value={selectingGraphPeriod}
          onChange={({ target: { value } }) =>
            setSelectingGraphPeriod(value)
          }
          options={graphDurationOptions}
        />
      </div>
      {isSuccess && resultSeries.length > 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={300}
            data={resultSeries}
            margin={{
              left: -10
            }}>
            <defs>
              <linearGradient
                id="first-line-gradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="5%" stopColor="#509ec6" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#509ec6" stopOpacity={0} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient
                id="2nd-line-gradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="5%" stopColor="#FF7BAC" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FF7BAC" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              vertical={false}
              stroke="#265d84"
              strokeWidth={1}
            />
            <XAxis dataKey="name" />
            <YAxis axisLine={false} />
            {selectingGraphCategory === GRAPH_CATEGORY.VOTE && (
              <>
                <Tooltip
                  contentStyle={{
                    borderRadius: 8
                  }}
                  labelFormatter={(value) => `Time: ${value}`}
                  formatter={(value, name) => {
                    switch (name) {
                      case 'votedUp':
                        return [`Voted Up: ${value}`];
                      case 'votedDown':
                        return [`Voted Down: ${value}`];
                      default:
                        return value;
                    }
                  }}
                />
                <Legend
                  onMouseEnter={(e) => setHiddenLine(e.value)}
                  onMouseLeave={() => setHiddenLine(null)}
                  iconType="circle"
                  iconSize={12}
                  verticalAlign="top"
                  align="right"
                  margin={{
                    top: -20
                  }}
                  height={60}
                  formatter={(value) => {
                    switch (value) {
                      case 'votedUp':
                        return 'Voted Up';
                      case 'votedDown':
                        return 'Voted Down';
                      default:
                        return value;
                    }
                  }}
                  wrapperStyle={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    gap: 8
                  }}
                />
                <Area
                  style={{
                    opacity: hiddenLine === 'votedDown' ? 0.1 : 1,
                    transition: 'opacity 0.3s'
                  }}
                  type="monotone"
                  dataKey="votedUp"
                  stroke="#509ec6"
                  strokeWidth={2}
                  fill="url(#first-line-gradient)"
                  dot={{
                    r: 5,
                    fill: '#509ec6',
                    strokeWidth: 1,
                    stroke: '#fff',
                    fillOpacity: 1
                  }}
                  activeDot={{
                    r: 8,
                    fill: '#509ec6',
                    strokeWidth: 2,
                    fillOpacity: 1
                  }}
                />
                <Area
                  style={{
                    opacity: hiddenLine === 'votedUp' ? 0.1 : 1,
                    transition: 'opacity 0.3s'
                  }}
                  type="monotone"
                  dataKey="votedDown"
                  stroke="#FF7BAC"
                  strokeWidth={2}
                  fill="url(#2nd-line-gradient)"
                  dot={{
                    r: 5,
                    fill: '#FF7BAC',
                    strokeWidth: 1,
                    stroke: '#fff',
                    fillOpacity: 1
                  }}
                  activeDot={{
                    r: 8,
                    fill: '#FF7BAC',
                    strokeWidth: 2,
                    fillOpacity: 1
                  }}
                />
              </>
            )}
            {selectingGraphCategory === GRAPH_CATEGORY.WORKED_HOURS && (
              <>
                <Tooltip
                  contentStyle={{
                    borderRadius: 8
                  }}
                  labelFormatter={(value) => `Time: ${value}`}
                  formatter={(value, name) => {
                    switch (name) {
                      case 'workedHours':
                        return [`Worked: ${value} hours`];
                      default:
                        return value;
                    }
                  }}
                />
                <Legend
                  onMouseEnter={(e) => setHiddenLine(e.value)}
                  onMouseLeave={() => setHiddenLine(null)}
                  iconType="circle"
                  iconSize={12}
                  verticalAlign="top"
                  align="right"
                  margin={{
                    top: -20
                  }}
                  height={60}
                  formatter={(value) => {
                    switch (value) {
                      case 'workedHours':
                        return 'Worked Hours';
                      default:
                        return value;
                    }
                  }}
                  wrapperStyle={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    gap: 8
                  }}
                />
                <Area
                  style={{
                    opacity: hiddenLine === 'votedUp' ? 0.1 : 1,
                    transition: 'opacity 0.3s'
                  }}
                  type="monotone"
                  dataKey="workedHours"
                  stroke="#FF7BAC"
                  strokeWidth={2}
                  fill="url(#2nd-line-gradient)"
                  dot={{
                    r: 5,
                    fill: '#FF7BAC',
                    strokeWidth: 1,
                    stroke: '#fff',
                    fillOpacity: 1
                  }}
                  activeDot={{
                    r: 8,
                    fill: '#FF7BAC',
                    strokeWidth: 2,
                    fillOpacity: 1
                  }}
                />
              </>
            )}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
HorizontalGraph.propTypes = {
  containerClassName: PropTypes.string
};

export default HorizontalGraph;
