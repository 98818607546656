import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/td-logo-original.svg';
import './styles.scss';
import { useGetInterviewInfoByHostRoomCodeQuery } from '../../queries/Interview';
import PostInterviewForm from '../PostInterviewForm';

const Interview = () => {
  const history = useHistory();
  const [interviewComponent, setInterviewComponent] = useState();
  const [isPostInterview, setIsPostInterview] = useState(false);
  const params = useParams();
  const { roomCode } = params;

  const { data } = useGetInterviewInfoByHostRoomCodeQuery({
    host_room_code: roomCode
  });

  //if the interview id is present, the current room code is from the host, so we allow post interview process
  const isPostInterviewAllowed = Boolean(data?.id);

  useEffect(() => {
    const loadInterviewComponent = async () => {
      const module = await import('@100mslive/roomkit-react');
      const { HMSPrebuilt } = module;
      setInterviewComponent(HMSPrebuilt);
    };
    loadInterviewComponent();
  }, []);

  const goToDashboardPage = () => {
    history.push('/');
  };

  if (!interviewComponent) {
    return null;
  }

  if (isPostInterview) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100vw"
        height="100vh"
        padding="16px"
        className="interview-page"
        gap="1rem">
        <Box display="flex" justifyContent="center">
          <Logo className="logo w-75" />
        </Box>
        <PostInterviewForm
          clientId={data?.client_id}
          interviewId={data?.id}
          submitButton={{
            text: 'SUBMIT',
            onClick: goToDashboardPage
          }}
          cancelButton={{
            text: ' SUBMIT LATER',
            onClick: goToDashboardPage
          }}
        />
        <Box>
          <Typography>
            If you left the interview room by mistake, you can rejoin{' '}
            <Link
              to={`/interview/${roomCode}`}
              onClick={() => window.location.reload()}
              style={{ fontFamily: 'Lato', color: '#509EC6' }}>
              here
            </Link>
            .
          </Typography>
        </Box>
      </Box>
    );
  }

  const HMSPrebuilt = interviewComponent;

  return (
    <Box className="interview-page">
      <HMSPrebuilt
        roomCode={roomCode}
        onLeave={() => {
          if (isPostInterviewAllowed) {
            setIsPostInterview(true);
          }
        }}
      />
    </Box>
  );
};

export default Interview;
