import { useState } from 'react';
import { ACTIVITY_FEED_TABS } from '../../constants/common';
import MobileHeaderTabs from './mobile-header-tabs';
import MobileFeedList from './mobile-feed-list';
import MobileHistoryList from './mobile-history-list';

const HomeMobileActivityFeed = () => {
  const [activeTab, setActiveTab] = useState(ACTIVITY_FEED_TABS.FEED);

  return (
    <div className="flex flex-col h-full">
      <MobileHeaderTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === ACTIVITY_FEED_TABS.FEED && <MobileFeedList />}
      {activeTab === ACTIVITY_FEED_TABS.HISTORY && <MobileHistoryList />}
    </div>
  );
};

export default HomeMobileActivityFeed;
