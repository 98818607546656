import { useState } from 'react';
import { ACTIVITY_FEED_TABS } from '../../constants/common';
import DesktopHeaderTabs from './desktop-header-tabs';
import DesktopFeedList from './desktop-feed-list';
import DesktopHistoryList from './desktop-history-list';

const HomeDesktopActivityFeed = () => {
  const [activeTab, setActiveTab] = useState(ACTIVITY_FEED_TABS.FEED);

  return (
    <div className="flex flex-col h-full">
      <DesktopHeaderTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === ACTIVITY_FEED_TABS.FEED && <DesktopFeedList />}
      {activeTab === ACTIVITY_FEED_TABS.HISTORY && <DesktopHistoryList />}
    </div>
  );
};

export default HomeDesktopActivityFeed;
