import { ReactComponent as FilterIcon } from '@src/assets/images/filter-icon.svg';
import { ReactComponent as SearchIcon } from '@src/assets/images/search-icon.svg';

const HomeMobileActivityFeedFilters = () => {
  return (
    <div className="flex items-center w-full gap-4 ml-4">
      <hr className="w-[1px] bg-td-brig h-[14px] border-none" />
      <SearchIcon className="size-[30px]" />
      <FilterIcon className="size-[26px] fill-td-blissful-blue" />
    </div>
  );
};

export default HomeMobileActivityFeedFilters;
